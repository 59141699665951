<template>
  <div class="personInfoData">
    <!-- 我的个人资料 -->
    <van-nav-bar :title="$t('personalData.pdTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="(item, index) in newFiledList"
                           :key="index"
                           :title="item.name"
                           :name="index">
          <van-form>
            <div class="peField"
                 v-for="(it, idx) in item.origin"
                 :key="idx">
              <!-- 不可编辑的输入框（除开关类型和文本域） -->
              <van-field v-if="
                !it.app_canedit &&
                it.controltype != 'checkbox' &&
                it.controltype != 'memo'
              "
                         class="not_canedi"
                         :label="it.chnname"
                         v-model="it.fieldValue"
                         readonly />
              <!-- 可编辑的普通输入框 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'edit' "
                         :required="it.mustinput"
                         :label="it.chnname"
                         right-icon="edit"
                         v-model="it.fieldValue"
                         :placeholder="$t('module.PleaseEnter')" />
              <!-- 下拉选择器 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'combobox'"
                         readonly
                         clickable
                         right-icon="edit"
                         name="picker"
                         :required="it.mustinput"
                         :value="it.fieldValue"
                         :label="it.chnname"
                         :placeholder="$t('module.PleaseChoose')"
                         @click="getPickerData(it, index, idx)" />
              <!-- 不可编辑的开关 -->
              <van-field v-else-if="
                it.app_canedit == false && it.controltype == 'checkbox'
              "
                         name="switch"
                         class="not_canedi"
                         :label="it.chnname">
                <template #input>
                  <van-switch v-model="newFiledList[index].origin[idx].fieldValue"
                              disabled
                              size="20" />
                </template>
              </van-field>
              <!-- 可编辑的开关 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'checkbox'"
                         right-icon="edit"
                         name="switch"
                         :required="it.mustinput"
                         :label="it.chnname">
                <template #input>
                  <van-switch v-model="newFiledList[index].origin[idx].fieldValue"
                              size="20" />
                </template>
              </van-field>
              <!-- 不可编辑的文本域 -->
              <van-field v-else-if="it.app_canedit == false && it.controltype == 'memo'"
                         :label="it.chnname"
                         class="not_canedi"
                         v-model="it.fieldValue"
                         rows="1"
                         autosize
                         type="textarea" />
              <!-- 可编辑的文本域 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'memo'"
                         :label="it.chnname"
                         v-model="it.fieldValue"
                         :required="it.mustinput"
                         right-icon="edit"
                         rows="3"
                         autosize
                         type="textarea"
                         :placeholder="$t('module.PleaseEnter')" />
              <!-- 日期选择器 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'datetime'"
                         clickable
                         :readonly="it.mustinput"
                         :clearable="!it.mustinput"
                         clear-trigger="always"
                         :required="it.mustinput"
                         right-icon="edit"
                         name="datePicker"
                         :label="it.chnname"
                         :value="formatDate(it.fieldValue, 'yyyy-MM-dd')"
                         :placeholder="$t('module.SelectDate')"
                         @clear="clearDate(index, idx)"
                         @click="getDatePicker(index, idx)" />
              <!-- 日期时间选择器 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'longdatetime'"
                         clickable
                         :readonly="it.mustinput"
                         :clearable="!it.mustinput"
                         clear-trigger="always"
                         :required="it.mustinput"
                         right-icon="edit"
                         name="datetimePicker"
                         :label="it.chnname"
                         :value="formatDate(it.fieldValue)"
                         :placeholder="$t('module.SelectDateTime')"
                         @clear="clearDate(index, idx)"
                         @click="getDateTimePicker(index, idx)" />
              <!-- maskedit匹配框 -->
              <van-field v-else-if="it.app_canedit && it.controltype == 'maskedit' "
                         :required="it.mustinput"
                         :label="it.chnname"
                         right-icon="edit"
                         v-model="it.fieldValue"
                         :placeholder="$t('module.PleaseEnter')"
                         :rules="[
                { required: it.mustinput, message: ' ' },
                it.maskeditstr === 'Email'
                  ? {
                      type: 'email',
                      message: $t('personalData.maskedms1'),
                      trigger: ['onBlur', 'onChange']
                    }
                  : {},
                it.maskeditstr === 'Telephone'
                  ? {
                      pattern: /^1\d{10}$/,
                      message: $t('personalData.maskedms2'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'IdCardNum'
                  ? {
                      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                      message: $t('personalData.maskedms3'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'Int'
                  ? {
                      pattern: /^-?[1-9]\d*$/,
                      message: $t('personalData.maskedms4'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'PositiveInt'
                  ? {
                      pattern: /^[1-9]\d*$/,
                      message: $t('personalData.maskedms5'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'NegtiveInt'
                  ? {
                      pattern: /^-[1-9]\d*$/,
                      message: $t('personalData.maskedms6'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'Float' && it.maskeditstrNum == '0'
                  ? {
                      pattern: /^(-?\d+)(\.\d+)?$/,
                      message: $t('personalData.maskedms7'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'Float' && it.maskeditstrNum != '0'
                  ? {
                      pattern: it.maskeditstrpattern,
                      message: $t('personalData.maskedms8', [it.maskeditstrNum]),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'PositiveFloat' &&
                it.maskeditstrNum === '0'
                  ? {
                      pattern: /^\d+(\.\d+)?$/,
                      message: $t('personalData.maskedms9'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'PositiveFloat' &&
                it.maskeditstrNum != '0'
                  ? {
                      pattern: it.maskeditstrpattern,
                      message: $t('personalData.maskedms10', [it.maskeditstrNum]),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'NegtiveFloat' &&
                it.maskeditstrNum === '0'
                  ? {
                      pattern: /^((-\d+(\.\d+)?)|(0+(\.0+)?))$/,
                      message: $t('personalData.maskedms11'),
                      trigger: 'onBlur'
                    }
                  : {},
                it.maskeditstr === 'NegtiveFloat' &&
                it.maskeditstrNum != '0'
                  ? {
                      pattern: it.maskeditstrpattern,
                      message: $t('personalData.maskedms12', [it.maskeditstrNum]),
                      trigger: 'onBlur'
                    }
                  : {}
              ]"
                         @input="val => {keyup(it)}">
              </van-field>

            </div>
          </van-form>
        </van-collapse-item>
      </van-collapse>
      <!-- 普通下拉选的弹窗 -->
      <van-popup v-model="showPicker"
                 position="bottom"
                 round>
        <van-picker show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"></van-picker>
      </van-popup>
      <!-- 日期选择弹窗 -->
      <van-popup v-model="showDatePicker"
                 position="bottom"
                 round>
        <van-datetime-picker type="date"
                             v-model="pickDate"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :formatter="formatter"
                             @confirm="onDateConfirm"
                             @cancel="showDatePicker = false" />
      </van-popup>
      <!-- 日期时间选择弹窗 -->
      <van-popup v-model="showDateTimePicker"
                 position="bottom"
                 round>
        <van-datetime-picker type="datetime"
                             v-model="pickDateTime"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :formatter="formatter"
                             @confirm="onDateTimeConfirm"
                             @cancel="showDateTimePicker = false" />
      </van-popup>
    </div>
    <div class="footer">
      <van-button type="info"
                  size="small"
                  @click="savePersonInfo"
                  :disabled="isDisable">{{$t('module.Save')}}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getEmpPersonInfo, saveEmpPersonInfo } from '@api/wx.js'
import { GetFilterDropDownList } from '@api/wx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      fieldlist: [], // 个人资料字段信息
      valuelist: [], // 个人资料字段对应的值
      newFiledList: [],
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      showPicker: false,
      columns: [], // 下拉选项数组
      parentIdx: '', // 数组下标
      chaildIdx: '', // 子数组下标
      showDatePicker: false,
      showDateTimePicker: false,
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(2121, 11, 31),
      pickDate: new Date(), // 日期
      pickDateTime: new Date(), // 日期时间
      // edit/combobox/bit/datetime/longdatetime/memo
      isDisable: false
    }
  },
  created () {
    this.getPersonInfo()
  },
  methods: {
    // 清除日期或时间
    clearDate (index, idx) {
      this.newFiledList[index].origin[idx].fieldValue = null
    },
    onClickLeft () {
      this.$router.push('/personCen')
    },
    keyup (list) {
      if (list.fieldValue)
      {
        if (list.maskeditstr == 'Int')
        {
          // 正负整数和0
          list.fieldValue = list.fieldValue.replace(/[^\-?\d]/g, '')
          if (list.fieldValue.lastIndexOf('-') > 0)
          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          let ary = list.fieldValue.split('')
          if (ary.length > 0)
          {
            if (ary.length > 1 && ary[0] == 0)
            {
              list.fieldValue = ary[1]
            } else if (ary.length > 1 && ary[0] == '-')
            {
              if (ary[1] == 0 || ary[1] == '-')
              {
                list.fieldValue = ''
              }
            }
          }
        } else if (list.maskeditstr == 'PositiveInt')
        {
          // 正整数
          list.fieldValue = list.fieldValue.replace(/^(0+)|[^\d]+/g, '')
        } else if (list.maskeditstr == 'NegtiveInt')
        {
          // 负整数
          list.fieldValue = list.fieldValue.replace(/^(0+)|[^\-?\d]+/g, '')
          if (list.fieldValue.lastIndexOf('-') > 0)
          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          let ary = list.fieldValue.split('')
          if (ary.length > 0 && ary[0] == '-')
          {
            if (ary[0] == '-' && ary[1] == 0)
            {
              list.fieldValue = ary[0]
            }
          } else
          {
            list.fieldValue = ''
          }
        } else if (
          list.maskeditstr === 'Float' ||
          list.maskeditstr === 'PositiveFloat' ||
          list.maskeditstr === 'NegtiveFloat'
        )
        {
          list.fieldValue = list.fieldValue.replace(/[^\-?\d.]/g, '')
          let ary = list.fieldValue.split('')
          if (list.fieldValue.lastIndexOf('-') > 0)
          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('-')
            )
          }
          if (ary[0] == '.')
          {
            list.fieldValue = ''
          }
          if (ary.length > 1 && ary[0] == 0 && ary[1] != '.')
          {
            list.fieldValue = ary[1]
          }
          if (list.fieldValue.split('.').length > 2)
          {
            list.fieldValue = list.fieldValue.substr(
              0,
              list.fieldValue.lastIndexOf('.')
            )
          }
          if (ary[0] == '-' && ary[1] == '.')
          {
            list.fieldValue = '-'
          }
        }
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm) +
            ':' +
            this.add0(s)
          )
        }
      } else
      {
        return ''
      }
    },
    formatter (type, val) {
      if (type === 'year')
      {
        return val + '年'
      }
      if (type === 'month')
      {
        return val + '月'
      }
      if (type === 'day')
      {
        return val + '日'
      }
      return val
    },
    // 日期选择回调
    onDateConfirm (value) {
      this.showDatePicker = false
    },
    // 查询个人信息
    getPersonInfo () {
      getEmpPersonInfo({
        empid: this.userInfo.empid
      }).then(res => {
        this.fieldlist = res.fieldlist
        this.valuelist = res.valuelist
        let afterData = []
        let tempArr = []
        if (this.fieldlist.length > 0 && this.valuelist.length > 0)
        {
          this.fieldlist.forEach(el => {
            // 小数，需要知道位数:Float{0}/PositiveFloat{0}/NegtiveFloat{0}
            if (el.maskeditstr)
            {
              if (el.maskeditstr.indexOf('Float') > -1)
              {
                const ir = el.maskeditstr.split('Float')
                el.maskeditstr = ir[0] + 'Float'
                el.maskeditstrNum = ir[1].split('{')[1].split('}')[0]
                if (el.maskeditstrNum != '0')
                {
                  // 带位数控制的，要先eval转义正则表达式
                  switch (el.maskeditstr)
                  {
                    case 'Float':
                      el.maskeditstrpattern = eval(
                        '/^\\d+(\\.\\d{1,' + el.maskeditstrNum + '})?$/'
                      )
                      break
                    case 'PositiveFloat':
                      el.maskeditstrpattern = eval(
                        '/^[1-9]\\d+(\\.\\d{1,' + el.maskeditstrNum + '})?$/'
                      )
                      break
                    case 'NegtiveFloat':
                      el.maskeditstrpattern = eval(
                        '/^-[1-9]\\d+(\\.\\d{1,' + el.maskeditstrNum + '})?$/'
                      )
                      break
                    default:
                      break
                  }
                }
              }
            } else
            {
              el.maskeditstr = ''
            }
            Object.keys(this.valuelist[0]).forEach((it, index) => {
              if (el.fieldname == it)
              {
                el.fieldValue = this.valuelist[0][it]
              }
            })
          })
          // 遍历分组
          for (let i = 0; i < this.fieldlist.length; i++)
          {
            if (tempArr.indexOf(this.fieldlist[i].appgroupname) === -1)
            {
              let arr = []
              arr.push(JSON.parse(JSON.stringify(this.fieldlist[i])))
              afterData.push({
                name: this.fieldlist[i].appgroupname,
                origin: arr
              })
              tempArr.push(this.fieldlist[i].appgroupname)
            } else
            {
              for (let j = 0; j < afterData.length; j++)
              {
                if (afterData[j].name == this.fieldlist[i].appgroupname)
                {
                  afterData[j].origin.push(
                    JSON.parse(JSON.stringify(this.fieldlist[i]))
                  )
                  break
                }
              }
            }
          }
        }
        this.newFiledList = afterData
        // this.newFiledList = JSON.parse(JSON.stringify(afterData))
        // console.log(this.newFiledList, 'ressssssssss');
      })
    },
    // 获取下拉选内容
    getPickerData (it, index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      const listsql = it.listsql
      this.columns = []
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: listsql
      }).then(res => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.columns.push(res.data[i].dicvalue)
          }
        }
        // console.log(res)
      })
      this.showPicker = true
    },
    // 下拉弹窗确认回调
    onConfirm (value) {
      this.newFiledList[this.parentIdx].origin[
        this.chaildIdx
      ].fieldValue = value
      this.showPicker = false
    },
    // 点击日期选择事件
    getDatePicker (index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      this.showDatePicker = true
    },
    // 日期选择回调
    onDateConfirm (value) {
      const val = this.formatDate(value, 'yyyy-MM-dd')
      this.pickDate = val
      this.newFiledList[this.parentIdx].origin[this.chaildIdx].fieldValue = val
      this.showDatePicker = false
    },
    // 点击日期时间选择事件
    getDateTimePicker (index, idx) {
      this.parentIdx = index // 数组下标
      this.chaildIdx = idx
      this.showDateTimePicker = true
    },
    // 日期时间选择回调
    onDateTimeConfirm (value) {
      const val = this.formatDate(value)
      this.pickDateTime = val
      this.newFiledList[this.parentIdx].origin[this.chaildIdx].fieldValue = val
      this.showDateTimePicker = false
    },

    // 保存按钮
    savePersonInfo () {
      let array = JSON.parse(JSON.stringify(this.newFiledList))
      let editLsit = []
      let finalAry = []
      if (array.length > 0)
      {
        array.forEach(ele => {
          if (ele.origin.length > 0)
          {
            ele.origin.forEach(it => {
              if (it.app_canedit == true || it.app_canedit == 'true')
              {
                editLsit.push(it)
              }
            })
          }
        })
      }
      if (editLsit.length > 0)
      {
        for (let i = 0; i < editLsit.length; i++)
        {
          const item = editLsit[i]
          if (item.controltype == 'checkbox')
          {
            item.fieldValue =
              item.fieldValue == true || item.fieldValue == 'true' ? '1' : '0'
          }
          let obj = {
            fieldname: item.fieldname,
            fieldvalue: item.fieldValue === null ? 'null' : item.fieldValue
          }
          if (
            (item.mustinput == true || item.mustinput == 'true') &&
            !item.fieldValue
          )
          {
            Toast.fail(this.$t('personalData.pdTxt2'))
            return false
          } else
          {
            finalAry.push(obj)
          }
        }
      }
      saveEmpPersonInfo({
        empid: this.userInfo.empid,
        data: finalAry
      }).then(res => {
        if (res.iserror == 0)
        {
          Toast.success(this.$t('personalData.pdTxt3'))
          this.getPersonInfo()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personInfoData {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    /deep/.van-collapse {
      .van-collapse-item {
        .van-collapse-item__title {
          .van-cell__title {
            font-size: 0.48rem;
            font-family: Source Han Sans CN;
            color: #000;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            .peField {
              .van-field {
                .van-field__label {
                  width: 8em;
                }
              }
              .not_canedi {
                .van-field__value {
                  .van-field__body {
                    .van-field__control {
                      color: #999999;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>